import React from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import shape1 from "@images/shapes/cta-1-shape-1.png";
import shape2 from "@images/shapes/cta-1-shape-2.png";
import shape3 from "@images/shapes/cta-1-shape-3.png";
import moc from "@images/mairlin/mairlin_1.webp";

import { SOLUTIONS_MAIRLIN_DATA } from "@data";

const SolutionsMairlin = () => {
  const { sectionTitle, paragraph,options } = SOLUTIONS_MAIRLIN_DATA;
  return (
    <section className="cta-one" style={{ background: `#f3f7fc` }}>
      <img src={shape1} className="cta-one__bg-shape-1" alt="" />
      <img src={shape2} className="cta-one__bg-shape-2" alt="" />
      <Container>
        <Row>
          <Col lg={6}>
            <div
              className="cat_one_moc wow fadeInUp  d-none d-lg-block"
              data-wow-duration="1500ms"
            >
              <div
                className="cat_one_moc-bg"
                style={{ backgroundImage: `url(${shape3})` }}
              ></div>
              <img src={moc} alt="Cat-1-Moc-1 Image" />
            </div>
          </Col>
          <Col lg={6}>
            <div className="cat_one_content">
              <div className="block-title">
                <p>{sectionTitle.text}</p>
                <h3>{sectionTitle.title}</h3>
              </div>
              <div
                className="cat_one_moc wow fadeInUp d-lg-none"
                data-wow-duration="1500ms"
              >
                <div
                  className="cat_one_moc-bg"
                  style={{ backgroundImage: `url(${shape3})` }}
                ></div>
                <img src={moc} alt="Cat-1-Moc-1 Image" />
              </div>
              <div className="cta-two__text new-line">
                <p>{paragraph}</p>
              </div>

              <div style={{ textAlign: `center` }}>
                <a href="https://www.mairlin.com" target="_blank" rel="noopener noreferrer" className="thm-btn pricing-one__btn">
                  <span>Use Mairlin</span>
                 </a>
              </div>
              
              
              <ul className="list-unstyled cta-two__list">
                {options.map(({ label }, index) => (
                  <li key={`options-key-${index}`}>
                    <i className="fa fa-check-circle"></i>
                    {label}
                  </li>
                ))}
              </ul>

            </div>
          </Col>
        </Row>
      </Container>
    </section>
  ) ;
};

export default SolutionsMairlin;
