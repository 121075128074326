import React from "react";
import {Row,Col, Container } from "react-bootstrap";
import { Link } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { SOLUTIONS_PICTURES_DATA } from "@data";
SwiperCore.use([Pagination, Autoplay]);

const SolutionsPictures = () => {
  const CarouselOptions = {
    speed: 2000,
    spaceBetween: 30,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    pagination: {
      el: "#app-shot-swiper-pagination",
      type: "bullets",
      clickable: "true"
    },
    slidesPerView: 4,
    slidesPerGroup: 4,
    style:{ textAlign: "center" },
    // Responsive breakpoints
    breakpoints: {
      0: { slidesPerView: 1, slidesPerGroup: 1, spaceBetween: 30 },
      480: { slidesPerView: 2, slidesPerGroup: 1, spaceBetween: 30 },
      600: { slidesPerView: 3, slidesPerGroup: 1, spaceBetween: 30 },
      991: { slidesPerView: 4, slidesPerGroup: 1, spaceBetween: 30 },
      1000: { slidesPerView: 4, slidesPerGroup: 1, spaceBetween: 30 },
      1200: { slidesPerView: 4, slidesPerGroup: 1, spaceBetween: 30 }
    }
  };
  const { sectionTitle, posts } = SOLUTIONS_PICTURES_DATA;
  return (
    <section className="app-shot-one" id="solutions-pictures"
    style={{ background: `#f3f7fc` }}>
      <Container>
        <div className="block-title text-center">
          <p>{sectionTitle.text}</p>
          <h3>{sectionTitle.title}</h3>
        </div>


        <Swiper
          className="app-shot-one__carousel owl-dot-type1"
          {...CarouselOptions}
        >
          {posts.map(({ image }, index) => (
            <SwiperSlide className="item" key={`app-shot-key-${index}`}>
              <img src={image} alt="Awesome Image" />
            </SwiperSlide>
          ))}
        </Swiper>


      </Container>
    </section>
  );
};

export default SolutionsPictures;
