import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import bgShape1 from "@images/banner/banner-shape-1-1.png";
import bgShape2 from "@images/banner/banner-shape-1-2.png";
import bannerBG from "@images/banner/banner-bg-1.png";
import bannerShape1 from "@images/shapes/banner-1-1.png";
import bannerShape2 from "@images/banner/banner-shape-1-4.png";
import image from "@images/banner/banner-img-1.png";

const SolutionsIntro = () => {
  return (
    <section className="banner-one" id="qawhat-intro">
      <img src={bgShape1} alt="Banner-Shape-1" className="banner-shape-1" />
      <div
        className="banner-one__bg"
        style={{ backgroundImage: `url(${bannerBG})` }}
      ></div>
      <Container>
        <Row>
          <Col xl={8} lg={8}>
            <div className="banner-one__content">
            <h3>
              Our Solutions to Maximize Impact of GenAI
            </h3>

            <p>
              The solutions we develop allow you to break free from the limitations inherent to ChatGPT: specialization, security, personalization, multi-modality, non-conversational industrialization.
              <br />Our technology stack integrates the latest advancements in the field and the best architectural practices: Serverless, Fusion Retrieval Augmented Generation, Langchain, API,...
              
              </p>
            </div>
          </Col>

        </Row>
      </Container>
    </section>
  );
};

export default SolutionsIntro;
