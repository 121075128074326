import React from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { SOLUTIONS_QAWHAT_DATA } from "@data";
const SolutionsQawhat = () => {
  const { sectionTitle, image, paragraph, options,posts } = SOLUTIONS_QAWHAT_DATA;
  const CarouselOptions = {
    speed: 2000,
    spaceBetween: 30,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    pagination: {
      el: "#app-shot-swiper-pagination",
      type: "bullets",
      clickable: "true"
    },
    slidesPerView: 2,
    slidesPerGroup: 1,
    style:{ textAlign: "center" },
    // Responsive breakpoints
    breakpoints: {
      0: { slidesPerView: 1, slidesPerGroup: 1, spaceBetween: 30 },
      480: { slidesPerView: 1, slidesPerGroup: 1, spaceBetween: 30 },
      600: { slidesPerView: 1, slidesPerGroup: 1, spaceBetween: 30 },
      991: { slidesPerView: 2, slidesPerGroup: 1, spaceBetween: 100 },
      1000: { slidesPerView: 2, slidesPerGroup: 1, spaceBetween: 100 },
      1200: { slidesPerView: 2, slidesPerGroup: 1, spaceBetween: 100 }
    }
  };
  return (
    <section className="cta-two" style={{ background: `#FFFFFF` }}>
      <Container>
        <Row>
          <Col xl={6} className="col-xl-6">
            <div className="cta-two__content">
              <div className="block-title cus-pb">
                <p>{sectionTitle.text}</p>
                <h3>{sectionTitle.title}</h3>
              </div>
              <Swiper
              className="app-shot-one__carousel owl-dot-type1 d-xl-none"
              {...CarouselOptions}
              >
                {posts.map(({ image }, index) => (
                  <SwiperSlide className="item" key={`app-shot-key-${index}`}>
                    <img src={image} alt="Awesome Image" />
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className="cta-two__text new-line">
                <p>{paragraph}</p>
              </div>
              <ul className="list-unstyled cta-two__list">
                {options.map(({ label }, index) => (
                  <li key={`options-key-${index}`}>
                    <i className="fa fa-check-circle"></i>
                    {label}
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Col xl={6}>
            <Swiper
            className="app-shot-one__carousel owl-dot-type1  d-none d-xl-block"
            {...CarouselOptions}
            >
              {posts.map(({ image }, index) => (
                <SwiperSlide className="item" key={`app-shot-key-${index}`}>
                  <img src={image} alt="Awesome Image" />
                </SwiperSlide>
              ))}
            </Swiper>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SolutionsQawhat;

