import React from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import shape from "@images/shapes/cta-2-shape-1.png";
import { SOLUTIONS_BREEBS_DATA } from "@data";
const SolutionsBreebs = () => {
  const { sectionTitle, paragraph } = SOLUTIONS_BREEBS_DATA;
  return (
    <section className="cta-two" style={{ background: `#FFFFFF` }}>
      <img src={shape} className="cta-two__bg-shape-1" alt="" />
      <Container>
        <Row>
          <Col xl={6} className="col-xl-6">
            <div className="cta-two__content">
              <div className="block-title cus-pb">
                <p>{sectionTitle.text}</p>
                <h3>{sectionTitle.title}</h3>
              </div>

              <div
                className="cta-two__moc wow fadeInLeft d-xl-none"
                data-wow-duration="1500ms"
              >
                <iframe width="560" height="315" src="https://www.youtube.com/embed/5f63WljU7S4?autoplay=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>

              </div>

              <div className="cta-two__text new-line">
                <p>{paragraph}</p>
              </div>
              <div style={{ textAlign: `center` }}>
                <a href="https://www.breebs.com" target="_blank" rel="noopener noreferrer" className="thm-btn pricing-one__btn">
                  <span>Use Breebs</span>
                 </a>
              </div>
            </div>
          </Col>
          <Col xl={6}>
            <div
              className="cta-two__moc wow fadeInLeft d-none d-xl-block"
              data-wow-duration="1500ms"
            >
              <iframe width="560" height="315" src="https://www.youtube.com/embed/5f63WljU7S4?autoplay=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
              
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SolutionsBreebs;
