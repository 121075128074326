import React from "react";
import Layout from "@components/layout";
import Header from "@components/header/header";

import SolutionsIntro from "@components/solutions-intro";
import SolutionsBreebs from "@components/solutions-breebs";
import SolutionsInspectMate from "@components/solutions-inspectmate";
import SolutionsMairlin from "@components/solutions-mairlin";
import SolutionsQawhat from "@components/solutions-qawhat";
import SolutionsLuzean from "@components/solutions-luzean";
import SolutionsStack from "@components/solutions-stack";
import SolutionsPictures from "@components/solutions-pictures";

import Contact from "@components/contact";
import Footer from "@components/footer";
import MobileNav from "@components/mobile-nav";


const Luzean = () => {
  return (
    <Layout pageTitle="Solutions || Prompt Breeders">
      <Header />
      <SolutionsIntro />
      <SolutionsMairlin />
      <SolutionsBreebs />
      <SolutionsInspectMate />
      <SolutionsQawhat />
      <SolutionsLuzean />
      <SolutionsStack />
      <SolutionsPictures />
      <Contact />
      <Footer />
      <MobileNav />
    </Layout>
  );
};

export default Luzean;
